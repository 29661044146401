<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Modifier un bureau",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Modifier un bureau",
      blocs: [],
      roomTypes: [],
      items: [
        {
          text: "Infrastructures",
          href: "/",
        },
        {
          text: "Bureaux",
          href: "/",
        },
        {
          text: "Modification",
          active: true,
        },
      ],
      officeForm: {
        officeNumber: "",
        officeBloc: "",
        roomType: "",
        uuid: null,
        // roomPhone: "",
      },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    officeForm: {
      officeNumber: { required, maxLength: maxLength(64) },
      officeBloc: { required },
    },
    
  },
  created: function () { 
    this.getBlocs();
  },
  mounted() {
      this.officeForm.uuid = this.$route.params.uid;
      this.fetchSingleOfficeData();
  },
  methods: {
    getBlocs() {
      var _this = this;
      this.$http
        .post("/infrastructure/blocs/list")
        .then((res) => {
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error, "error");
        })
        .finally(() => {});
    },

    fetchSingleOfficeData() {
      var _this = this;
      this.$http
        .post(
          "/infrastructure/office/getSingleOfficeDetails/" + this.officeForm.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var response = res.data.original.office;
          switch (status) {
            case 200:
              _this.officeForm.officeNumber = response.number;
              _this.officeForm.officeBloc = response.bloc_id;
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/infrastructure/office/update", this.officeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              setTimeout(function () {
                router.push({ name: "base.infrastructures.offices" });
              }, 3000);

              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error, "error");
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Numéro de bureau</label>
                    <input
                      id="deptName"
                      v-model="officeForm.officeNumber"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid':
                          submitted && $v.officeForm.officeNumber.$error,
                      }"
                    />
                    <div v-if="submitted && $v.officeForm.officeNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.officeForm.officeNumber.required">Le numéro est obligatoire.</span>
                      <span v-if="!$v.officeForm.officeNumber.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Bloc</label>
                    <select
                      v-model="officeForm.officeBloc"
                      :class="{
                        'is-invalid':
                          submitted && $v.officeForm.officeBloc.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected>Sélectionner une classe</option>
                      <option
                        v-for="bloc in blocs"
                        v-bind:value="bloc.id"
                        v-bind:key="bloc.id"
                      >
                        {{ bloc.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.officeForm.officeBloc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.officeForm.officeBloc.required"
                        >Le bloc est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>